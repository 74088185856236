<template>
  <div>
    <div class="dis align_center">
      <div class="icon"></div>
      <div class="service">我的申报</div>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="180"> </el-table-column>
      <el-table-column
        prop="title"
        label="申报标题"
        min-width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="source"
        label="组织单位"
        min-width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="contact"
        label="联系人"
        min-width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="mobile"
        label="联系电话"
        min-width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="报名时间"
        min-width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="operation"
        label="操作"
        min-width="180"
        align="center"
      >
        <template>
          <el-button type="text">查看申报详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
    v-if="tableData.length !=0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="paginations.page_index"
      :page-sizes="paginations.page_sizes"
      :page-size="paginations.page_size"
      :layout="paginations.layout"
      :total="paginations.total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      allTableData: [],
      tableData: [],
      total: "",
    };
  },
  created() {
    this.getdeclarelist();
  },
  methods: {
    getdeclarelist(item) {
      let data = {
        token: localStorage.eleToken,
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
      };

      this.$post("declares_user_list", data).then((res) => {
        this.allTableData = res.result;
       if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }

        //设置默认的分页数据
        if (this.allselectorder.length != 0) {
          this.selectorder = this.allselectorder.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.selectorder = [];
        }
        console.log(this.allselectorder, "this.allselectorder");
      });
    },
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.getdeclarelist()
      this.tableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },

    // 分页

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      this.getdeclarelist(page)
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.tableData = tables;
      }
    },

  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}
.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}
::v-deep .el-pagination {
  text-align: center;
  margin-top: 20px;
}
</style>
